import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { shareReplay } from 'rxjs/operators';
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: 'root'
})
export class CandidateEmployerListService {


  constructor(private readonly httpClient: HttpClient) { }

 
  //get  all Public candidate list
  getAllPublicCandidateList(
    currentPage: number,
    totalItemPerPage: number,
    // name:string = '',
    // email:string = '',
    category:string = '',
    country:string = '',
  ) {

// remove emty spaces 
  category = category.trim();
  country = country.trim();

    return this.httpClient.get(
      `${environment.baseUrl}/candidate/get-all-candidates-for-public-listing/${currentPage}/${totalItemPerPage}?category=${category}
      &country=${country}`
    );
  }

  //get specific candidate for public listing 
  getSpecificCandidateForPublicListing(Id:any) {
          return this.httpClient.get(
              `${environment.baseUrl}/candidate/get-specific-candidate-for-public-listing/${Id}`,
          );
  }

  //get all Admin candidate list
  getAllAdminCandidateList(
    currentPage: number,
    totalItemPerPage: number,
    name:string = '',
    email:string = '',
  ) {
    return this.httpClient.get(
      `${environment.baseUrl}/candidate/get-all-candidates-for-admin/${currentPage}/${totalItemPerPage}?name=${name}
      &email=${email}`
    );
  }

  //get all employers list
  getAllEmployerList(
    currentPage: number,
    totalItemPerPage: number,
    collegeOrEmail:string = ""
  ){
    return this.httpClient.get(
         `${environment.baseUrl}/employer/get-all-employers/${currentPage}/${totalItemPerPage}?collegeOrEmail=${collegeOrEmail}`
  );
  }


  //get specific candidate by id
  getSpecificCandidateById(id:any) {
   return this.httpClient.get(`${environment.baseUrl}/candidate/get-specific-candidate/${id}`)
  }

    //get specific candidate contract details
    getSpecificCandidateContractDetails(email:any) {
      return this.httpClient.get(`${environment.baseUrl}/contract-management/fetch-contract-pdf-of-candidate/${email}`)
     }

  //get specific candidate by id for employer
    getSpecificCandidateByIdForEmployer(id:any , employerEmail:any) {
      return this.httpClient.get(`${environment.baseUrl}/candidate/get-specific-candidate/${id}/${employerEmail}`)
  }

  //get specific candidate for employer
  getSpecificCandidateForEmployer(id:any , employerEmail:any){
    return this.httpClient.get(`${environment.baseUrl}/requested-candidate/get-unique-candidate-for-employer-listing/${id}/${employerEmail}`)
  }
  
  //get specific employer by id
  getSpecificEmployerById(id:any){
  return this.httpClient.get(`${environment.baseUrl}/employer/get-specific-employer/${id}`)
  }

  //assign email to employer
  assignEmailToEmployer(payload:any) {
    return this.httpClient.patch(`${environment.baseUrl}/candidate/update-candidate-key`,payload)
  }

  //get all candidates assigned to employer
  getAllCandidatesAssignedToEmployer(
    currentPage: number,
    totalItemPerPage: number,
    employerEmail: string,
    email: string,
    name: string,
    candidateEmail: string
) {
    return this.httpClient.get(
        `${environment.baseUrl}/candidate/get-candidates-for-employee/${currentPage}/${totalItemPerPage}/${encodeURIComponent(employerEmail)}?email=${encodeURIComponent(email)}&name=${encodeURIComponent(name)}&candidateEmail=${encodeURIComponent(candidateEmail)}`
    );
}

  //get all employers names
  getAllEmployersEmail() {
    return this.httpClient.get(`${environment.baseUrl}/employer/get-employers-names`)
  }

  //get all assigned candidates
  getAllAssignedCandidates(currentPage: number,
    totalItemPerPage: number, name: string , email:string , employerEmail:string){
    return this.httpClient.get(`${environment.baseUrl}/candidate/fetch-all-assign-candidates/${currentPage}/${totalItemPerPage}?name=${name}&email=${email}&employerEmail=${employerEmail}`)
  }

  //unAssigned candidate to employer
  unAssignEmailToEmployer(email:any , candidateId:any) {
    return this.httpClient.patch(`${environment.baseUrl}/candidate/unassign-candidate-from-employee?email=${email}&candidateId=${candidateId}`,{})
  }

  // terminate Candidate with it's id
  deleteSpecificCandidateById(id:any) {
    return this.httpClient.delete(`${environment.baseUrl}/candidate/delete-specific-candidate/${id}`)
   }

  // delete applied job
  deleteAppliedJob(id:any) {
      return this.httpClient.delete(`${environment.baseUrl}/apply-job-management/delete-specific-apply-job-info/${id}`)
     } 

  // terminate Candidate with it's id
  deleteSpecificEmployeerById(id:any) {
    return this.httpClient.delete(`${environment.baseUrl}/employer/delete-specific-employer/${id}`)
   }

  // generate request for candidate
  requestCandidate(email:any , id:any){
   return this.httpClient.patch(`${environment.baseUrl}/requested-candidate/create-requested-candidate/${email}/${id}`, null);
  } 

  //get all candidates for employer
  getAllCandidatesListForEmployer(currentPage: number,totalItemPerPage: number , employerEmail: any){
    return this.httpClient.get(`${environment.baseUrl}/requested-candidate/get-all-candidates-for-employer-listing/${currentPage}/${totalItemPerPage}/${employerEmail}`)
  }
  
  //get all requested candidate's list
  getAllRequestedCandidates(currentPage: number,totalItemPerPage: number){
    return this.httpClient.get(`${environment.baseUrl}/requested-candidate/fetch-requested-candidate-for-admin/${currentPage}/${totalItemPerPage}`)
  }

  //update requested candidate approved status
  updateApprovedSatus(candidateId:any , requestedStatus:any , employerEmail:any ){
    return this.httpClient.patch(`${environment.baseUrl}/requested-candidate/update-requested-candidate-from-admin/${candidateId}/${requestedStatus}/${employerEmail}`,null)
  }

  //get all employers who request for specific candidate
  getAllEmployersByCandidateId(currentPage: number,totalItemPerPage: number , candidateId:any){
    return this.httpClient.get(`${environment.baseUrl}/requested-candidate/get-all-requested-employers-for-unique-candidate/${currentPage}/${totalItemPerPage}/${candidateId}`)
  }

  //get all assigned employers for unique candidate
  getAllAssignedEmployersByCandidateId(currentPage: number,totalItemPerPage: number , candidateId:any){
      return this.httpClient.get(`${environment.baseUrl}/candidate/get-all-assigned-employers-for-unique-candidate/${currentPage}/${totalItemPerPage}/${candidateId}`)
  }

  //delete requested candidate 
  deleteRequestedCandidate(candidateId:any , employerEmail:any ){
      return this.httpClient.patch(`${environment.baseUrl}/requested-candidate/delete-requested-candidate/${candidateId}/${employerEmail}`,null)
    }


  //get additional certificates
  getAdditionalDocuments(id:any){
    return this.httpClient.get(`${environment.baseUrl}/candidate/get-specific-candidate-additional-details/${id}`)
  }
  //delete PD's certificates
  deletePDDocuments(id:any , pdsId:any){
      return this.httpClient.delete(`${environment.baseUrl}/candidate/delete-pds-by-id/${id}/${pdsId}`)
  }

  //save industry currency of candidate
  addIndustryCurrency(payload:any , id:any){
      return this.httpClient.patch(`${environment.baseUrl}/candidate/update-industry-currency-for-candidate/${id}`,payload)
    }

  //update industry currency of candidate
  updateIndustryCurrency(payload:any , id:any , updateSpecificDataId:any){
      return this.httpClient.patch(`${environment.baseUrl}/candidate/update-industry-currency/${id}/${updateSpecificDataId}`,payload)
    }  
  
  //delete industry currency by id 
  deleteIndustryCurrency(candidateId:any , industryCurrencyId: any){
    return this.httpClient.delete(`${environment.baseUrl}/candidate/delete-industry-currency/${candidateId}/${industryCurrencyId}`)
  }

  //save industry currency verification of candidate
  addIndustryCurrencyVerification(payload:any , id:any){
      return this.httpClient.patch(`${environment.baseUrl}/candidate/save-industry-currency-verification-for-candidate/${id}`,payload)
    }
  
  //update industry currency verification of candidate
  updateIndustryCurrencyVerification(payload:any , id:any , updateSpecificDataId:any){
      return this.httpClient.patch(`${environment.baseUrl}/candidate/update-industry-currency-verification/${id}/${updateSpecificDataId}`,payload)
    }   
  
  //delete industry currency verification by id 
  deleteIndustryCurrencyVerification(candidateId:any , industryCurrencyVerificationId: any){
    return this.httpClient.delete(`${environment.baseUrl}/candidate/delete-industry-currency-verification/${candidateId}/${industryCurrencyVerificationId}`)
  } 


  //save matrix of candidate
  addMatrix(payload:any , id:any){
      return this.httpClient.patch(`${environment.baseUrl}/candidate/update-matrix-for-candidate/${id}`,payload)
    }

  //delete matrix by id 
  deleteMatrix(candidateId:any , matrixId: any){
    return this.httpClient.delete(`${environment.baseUrl}/candidate/delete-matrix/${candidateId}/${matrixId}`)
  } 


  //save reference check  of candidate
  addReferenceCheck(payload:any , id:any){
      return this.httpClient.patch(`${environment.baseUrl}/candidate/update-refrence-check-for-candidate/${id}`,payload)
    }

  //delete reference check by id 
  deleteReferenceCheck(candidateId:any , referenceCheckId: any){
    return this.httpClient.delete(`${environment.baseUrl}/candidate/delete-refrence-check/${candidateId}/${referenceCheckId}`)
  } 


   //save pdLogAndPlan  of candidate
  addPdLogAndPlan(payload:any , id:any){
    return this.httpClient.patch(`${environment.baseUrl}/candidate/update-pdLogAndPlan-for-candidate/${id}`,payload)
  }

  //update pdLogAndPlan  of candidate
  updatePdLogAndPlan(payload:any , id:any ,pdLogAndPlanId: any ){
      return this.httpClient.patch(`${environment.baseUrl}/candidate/update-pdLogAndPlan/${id}/${pdLogAndPlanId}`,payload)
  }

  //delete pdLogAndPlan by id 
  deletePdLogAndPlan(candidateId:any , pdLogAndPlanId: any){
      return this.httpClient.delete(`${environment.baseUrl}/candidate/delete-pdLogAndPlan/${candidateId}/${pdLogAndPlanId}`)
    } 

  //save PD's  of candidate
  addPds(payload:any , id:any){
    return this.httpClient.patch(`${environment.baseUrl}/candidate/save-pds-for-candidate/${id}`,payload)
  }

   //update PD's  of candidate
   updatePds( id:any , payload:any){
    return this.httpClient.patch(`${environment.baseUrl}/candidate/update-pds-type-and-expiry/${id}`,payload)
  }

  //delete PD's by id 
  deletePds(candidateId:any , payload:any){
    return this.httpClient.patch(`${environment.baseUrl}/candidate/delete-pds-by-type/${candidateId}` , payload)
  } 

  //save TrainerEvidenceSummaryChecklist  of candidate
  addTrainerEvidenceSummaryChecklist(payload:any , id:any){
      return this.httpClient.patch(`${environment.baseUrl}/candidate/update-trainer-evidence-summary-checklist/${id}`,payload)
    }

  //delete TrainerEvidenceSummaryChecklist by id 
  deleteTrainerEvidenceSummaryChecklist(candidateId:any , pdsId: any){
    return this.httpClient.delete(`${environment.baseUrl}/candidate/delete-trainer-evidence-summary-checklist/${candidateId}/${pdsId}`)
  }

  //save USI Report  of candidate
  addUsiReport(payload:any , id:any){
      return this.httpClient.patch(`${environment.baseUrl}/candidate/update-usiReport-for-candidate/${id}`,payload)
    }

  //update USI Report  of candidate
  updateUsiReport(payload:any , id:any , UsiReportId :any){
    return this.httpClient.patch(`${environment.baseUrl}/candidate/update-usiReport-expiry/${id}/${UsiReportId}`,payload)
  } 

  //delete USI Report by id 
   deleteUsiReport(candidateId:any , pdsId: any){
      return this.httpClient.delete(`${environment.baseUrl}/candidate/delete-usi-report/${candidateId}/${pdsId}`)
    }  

    //save medical certificate  of candidate
    addMedicalCertificate(payload:any , id:any){
      return this.httpClient.patch(`${environment.baseUrl}/candidate/update-medicalCertificate-for-candidate/${id}`,payload)
    }  

    //update medical certificate  of candidate
    updateMedicalCertificate(payload:any , id:any , certificateId:any){
        return this.httpClient.patch(`${environment.baseUrl}/candidate/update-medical-certificate-type-and-expiry/${id}/${certificateId}`,payload)
      }  

    //delete medical certificate by id 
   deleteMedicalCertificate(candidateId:any , medicalCertificateId: any){
    return this.httpClient.delete(`${environment.baseUrl}/candidate/delete-medicalCertificate/${candidateId}/${medicalCertificateId}`)
  }  

    //save resume  of candidate
  addResume(payload:any , id:any){
        return this.httpClient.patch(`${environment.baseUrl}/candidate/update-resumeDocs-for-candidate/${id}`,payload)
      }  

   //delete resume by id 
  deleteResume(candidateId:any , resumeId: any){
    return this.httpClient.delete(`${environment.baseUrl}/candidate/delete-resumeDocs/${candidateId}/${resumeId}`)
  }

  //save additional  of candidate
    addAdditionalCertificate(payload:any , id:any){
      return this.httpClient.patch(`${environment.baseUrl}/candidate/save-additional-certificates-for-candidate/${id}`,payload)
   } 

  //update additional certificate 
    updateAdditionalCertificate(payload:any , id:any , certificateId:any){
        return this.httpClient.patch(`${environment.baseUrl}/candidate/update-additional-certificate/${id}/${certificateId}`,payload)
    } 

   //delete additional by id 
  deleteAdditionalCertificate(candidateId:any , resumeId: any){
    return this.httpClient.delete(`${environment.baseUrl}/candidate/delete-additional-certificate/${candidateId}/${resumeId}`)
  }


  //save education  of candidate
  addEducation(payload:any , id:any){
      return this.httpClient.patch(`${environment.baseUrl}/candidate/update-qualification-for-candidate/${id}`,payload)
  }

  //update education  of candidate
  updateEducation(payload:any , id:any , qualificationId :any){
      return this.httpClient.patch(`${environment.baseUrl}/candidate/update-candidate-qualification/${id}/${qualificationId}`,payload)
  }

  //get education listing
  getCandidateEducationListing(id:any){
    return this.httpClient.get(`${environment.baseUrl}/candidate/get-qualification-for-candidate/${id}`)
  }

  //delete candidate education certificate by id 
  deleteCandidateEducation(candidateId:any , educationId: any){
      return this.httpClient.delete(`${environment.baseUrl}/candidate/delete-qualification/${candidateId}/${educationId}`)
    } 

  //save candidate passport
  addCandidatePassport(id:any , payload:any ){
    return this.httpClient.patch(`${environment.baseUrl}/candidate/update-Passport-details-for-candidate/${id}`,payload)
  }

  //delete candidate passport
   deleteCandidatePassport(id:any , passportId:any ){
    return this.httpClient.delete(`${environment.baseUrl}/candidate/delete-passport-detail/${id}/${passportId}`)
   }

  //save candidate Drivers License
  addCandidateDriversLicense(id:any , payload:any ){
      return this.httpClient.patch(`${environment.baseUrl}/candidate/update-driving-license-details-for-candidate/${id}`,payload)
    }

  //delete candidate Drivers License
  deleteCandidateDriversLicense(id:any , passportId:any ){
      return this.httpClient.delete(`${environment.baseUrl}/candidate/delete-driving-license-detail/${id}/${passportId}`)
    }  
  
  //save candidate MediCare
  addCandidateMediCare(id:any , payload:any ){
    return this.httpClient.patch(`${environment.baseUrl}/candidate/update-mediCare-details-for-candidate/${id}`,payload)
  } 

   //delete candidate MediCare
  deleteCandidateMediCare(id:any , passportId:any ){
    return this.httpClient.delete(`${environment.baseUrl}/candidate/delete-mediCare-detail/${id}/${passportId}`)
  } 

  //save candidate MediBank
  addCandidateMediBank(id:any , payload:any ){
      return this.httpClient.patch(`${environment.baseUrl}/candidate/update-mediBank-details-for-candidate/${id}`,payload)
  } 

  //delete candidate MediBank
  deleteCandidateMediBank(id:any , passportId:any ){
      return this.httpClient.delete(`${environment.baseUrl}/candidate/delete-mediBank-detail/${id}/${passportId}`)
    } 

   //delete candidate other documents
   deleteCandidateOtherDocuments(id:any , passportId:any ){
    return this.httpClient.delete(`${environment.baseUrl}/candidate/delete-other-documents-details/${id}/${passportId}`)
  }   
  
  //get candidate by id
  getCandidateById(id:any){
    return this.httpClient.get(`${environment.baseUrl}/candidate/get-specific-candidate/${id}`)
  }

 
}  


