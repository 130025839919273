
<div class="banner-area">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="banner-text text">
                    <h1>Candidate <span>List</span></h1>
                    <p>Connecting Worldwide Talents and Businesses</p>

                    <div class="banner-form-area">
                        <form>
                            <div class="row">
                                <!-- <div class="col-md-4">
                                    <div class="form-group">
                                        <label
                                            ><i class="fas fa-building"></i
                                        ></label>
                                        <input
                                            type="text"
                                            class="form-control"
                                            placeholder="Search Name"
                                               [(ngModel)]="name"
                                            [ngModelOptions]="{standalone: true}"
                                        />
                                    </div>
                                </div> -->
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label
                                            ><i class="fas fa-tags"></i
                                        ></label>
                                        <input
                                            type="text"
                                            class="form-control"
                                            placeholder="Search Category"
                                               [(ngModel)]="category"
                                            [ngModelOptions]="{standalone: true}"
                                            style="border: 2px solid #cacacd; border-radius: 4px; padding-left: 10px;"
                                        />
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label
                                            ><i class="fas fa-globe"></i
                                        ></label>
                                        <input
                                            type="text"
                                            class="form-control"
                                            placeholder="Search Country"
                                               [(ngModel)]="country"
                                            [ngModelOptions]="{standalone: true}"
                                            style="border: 2px solid #cacacd; border-radius: 4px; padding-left: 10px;"
                                        />
                                    </div>
                                </div>
                            </div>

                            <button
                                type="submit"
                                (click)="getAllCandidateList()"
                                class="btn banner-form-btn"
                            >
                                Search
                            </button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- <div class="container back-link">
    <a routerLink="/home" class="cursor_pointer h5"><i class="fa fa-angle-double-left me-2"></i>Back</a>
</div> -->


<section class="job-area job-area-two pt-100">
    <div class="container">
        <div class="row">
            <ng-container *ngIf="candidateList?.length > 0; else noCandidateFound">
            <div class="col-lg-6" *ngFor="let data of candidateList ; let i = index">
                <div class="job-item">
                    <div class="job-inner align-items-center">
                        <div class="job-inner-left">
                            <h3>
                                <!-- Candidate {{i+1}} -->
                                {{ data?.publicName}} 
                            </h3>
                            <ul class="mt-3 unordered-list">
                                <li>
                                    <i class="fas fa-building"></i
                                    >{{ data?.category ? data?.category : "****" }}
                                </li>
                                <li>
                                    <i class="fas fa-globe"></i
                                    >{{ data?.country ? data?.country : "****" }}
                                </li>
                            </ul>

                            <ul class="mt-3 unordered-list">
                                <b>Experience</b
                                >:
                                <ng-container *ngIf="data?.yearsOfExperience?.length > 0 ; else noData">
                                <div
                                    *ngIf="data?.yearsOfExperience"
                                    class="mt-2"
                                >
                                    <span>
                                        <li
                                            *ngFor="
                                                let experience of data.yearsOfExperience
                                            "
                                        >
                                            <div
                                                class="d-flex justify-content-between w-100"
                                            >
                                                <div>
                                                    <h6>
                                                        {{
                                                            experience.jobTitle
                                                        }}
                                                    </h6>
                                                    <p>
                                                        {{
                                                            experience.companyName
                                                        }}
                                                    </p>
                                                </div>
                                                <div>
                                                    <ng-container
                                                        *ngIf="
                                                            experience?.endedMonth &&
                                                                experience?.endedYear;
                                                            else presentTemplate
                                                        "
                                                    >
                                                        <h6>
                                                            ({{
                                                                experience?.startedYear
                                                            }}) - ({{
                                                                experience?.endedYear
                                                            }})
                                                        </h6>
                                                    </ng-container>

                                                    <ng-template
                                                        #presentTemplate
                                                    >
                                                        <div>
                                                            <h6>
                                                                ({{
                                                                    experience?.startedYear
                                                                }}) - present
                                                            </h6>
                                                        </div>
                                                    </ng-template>
                                                </div>
                                            </div>
                                        </li>
                                    </span>
                                </div>
                            </ng-container>
                            <ng-template #noData>
                             <p>********</p>
                            </ng-template>
                            </ul>

                            <div >
                                <ul class="mt-3 unordered-list">
                                    <ng-container *ngIf="data?.skills?.length > 0; else noSkills">
                                        <b>Skills</b>
                                        <div *ngFor="let skills of data?.skills">
                                        <span>
                                            <!-- <b>Skills</b> : {{ data.skills.join(", ") }} -->
                                               {{skills?.skill}}
                                        </span>
                                    </div>
                                    </ng-container>
                                </ul>
                                <ng-template #noSkills>
                                    <p><b>Skills</b> :</p>
                                    <span>********</span>
                                </ng-template>
                                
                            </div>
                            <a
                                class="mt-3"
                                [routerLink]="['/candidate-details',data?._id]"
                                >View Profile<i class="icofont-swoosh-right"></i
                            ></a>
                        </div>
                    </div>
                </div>
            </div>
        </ng-container>
        <ng-template #noCandidateFound>
            <div class="text-center" >
            <h5>No Data Found</h5>
            </div>
          </ng-template>
        </div>
    </div>

    <div *ngIf="candidateList?.length > 0">
    <nav aria-label="Page navigation example pagination" class="pagination-nav">
        <ul class="pagination unordered-list">
            <li class="page-item" [class.disabled]="currentPage === 1">
                <a
                    class="page-link cursor_pointer"
                    (click)="onPageChange(currentPage - 1)"
                    aria-label="Previous"
                >
                    <span aria-hidden="true">&laquo;</span>
                </a>
            </li>
            <li
                class="page-item"
                *ngFor="let page of [].constructor(totalPages); let i = index"
            >
                <a
                    class="page-link cursor_pointer"
                    (click)="onPageChange(i + 1)"
                    >{{ i + 1 }}</a
                >
            </li>
            <li class="page-item" [class.disabled]="currentPage === totalPages">
                <a
                    class="page-link cursor_pointer"
                    (click)="onPageChange(currentPage + 1)"
                    aria-label="Next"
                >
                    <span aria-hidden="true">&raquo;</span>
                </a>
            </li>
        </ul>
    </nav>

    <div class="pagination-controls pagination">
        <span>Page {{ currentPage }} of {{ totalPages }}</span>
    </div>
</div>

</section>


