import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { Subscription } from "rxjs";
import { AuthService } from "src/app/store/auth/auth.service";
declare var Calendly: any;
import { UserRole } from "src/app/shared/enums/roles";
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { LocalStorageService } from "src/app/shared/services/local-storage.service";

@Component({
    selector: "app-navbar",
    templateUrl: "./navbar.component.html",
    styleUrls: ["./navbar.component.scss"],
})
// routerLink="/admin/job-create"
// routerLink="/employer/employer-profile"
// routerLink="/candidate/candidate-profile"

export class NavbarComponent implements OnInit {
    loginAuthO = this.authService.userO;
    registerUser = this.authService.userO;
    private loginSub: Subscription;
    private registerSub: Subscription;
    user: boolean = false;
    updateLoginUser: boolean;
    userInfo: any = {};
    isLargeScreen: boolean = false;
    candidateLoggedIn: boolean = false;
    
    constructor(
        private readonly authService: AuthService,
        private readonly router: Router,
        private localStorageService: LocalStorageService,
    ) {
    }

    
    ngOnInit():void {
        // Expose the component instance globally
        (window as any).NavbarComponentInstance = this;

        const candidateLoggedIn = localStorage.getItem('candidateLoggedIn');
  
       if (candidateLoggedIn === 'true') {
        this.candidateLoggedIn = true;
        }
      }

      ngAfterViewInit() {
        setTimeout(() => {
            this.loginSub = this.loginAuthO.subscribe((data) => {
                // console.log('navbar wala data', data);
                if (!data) return;
                const userData = this.localStorageService.getUserData();
                if (userData && (Object.keys(userData).length > 0) && data?.isData === true || (data && data?.body !== null)) {
                    this.user = true;
                    console.log(this.user);
                    this.userInfo = data?.body ?? data;
                    console.log('user ka data' , this.userInfo)
                }
            });
        }, 0);
      }
    

    handleLogout() {
        this.user = false;
        this.userInfo = ''
        localStorage.removeItem("token");
        localStorage.removeItem("user-data");
        localStorage.removeItem("qualification");
        localStorage.removeItem("certificates");
        localStorage.removeItem("experience");
        localStorage.removeItem("candidateLoggedIn")
        this.authService.setNullUser();
        this.router.navigate(["/management-module/login"]);
    }

    isUserLoggedIn() {}
    // openCalendly(): void {
    //     if (this.userInfo?.role === 'employee') {
    //     // Use Calendly's popup widget to open the scheduling interface
    //     Calendly.initPopupWidget({
    //         url: "https://calendly.com/accounts-magnusrecruitment/30min", // Replace with your scheduling link
    //     });
    // }
    // }

    // updated code
    openCalendly(): void {
        // Use Calendly's popup widget to open the scheduling interface
        Calendly.initPopupWidget({
            url: "https://calendly.com/accounts-magnusrecruitment/15min", // Replace with your scheduling link
        });
    }

    ngOnDestroy() {
        if (this.loginSub) {
            this.loginSub.unsubscribe();
        }
        if (this.registerSub) {
            this.registerSub.unsubscribe();
        }
    }
}
